const publicMenuModule = {
    namespaced: true,
    state: () => ({
        districtSlug: '',
        publicMenu: {},
        sites: [],
        siteID: null,
        mealTimes: [],
        mealTimeId: null,
        categories: [],
        categoryId: null,
        nutritionItems: [],
        allergenColumns: [],
        nutritionColumns: [],
        announcementText: ''
    }),
    mutations: {
        setDistrictSlug(state, districtSlug) {
            state.districtSlug = districtSlug;
        },
        setPublicMenu(state, publicMenu) {
            state.publicMenu = publicMenu;
        },
        setSites(state, sites) {
            state.sites = sites;
        },
        setSiteID(state, siteID) {
            state.siteID = siteID;
        },
        setMealTimes(state, mealTimes) {
            state.mealTimes = mealTimes;
        },
        setMealTimeId(state, mealTimeId) {
            state.mealTimeId = mealTimeId;
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
        setCategoryId(state, categoryId) {
            state.categoryId = categoryId;
        },
        setNutritionItems(state, nutritionItems) {
            state.nutritionItems = nutritionItems;
        },
        setAllergenColumns(state, allergenColumns) {
            state.allergenColumns = allergenColumns;
        },
        setNutritionColumns(state, nutritionColumns) {
            state.nutritionColumns = nutritionColumns;
        },
        setAnnouncementText(state, text) {
            state.announcementText = text;
        },
        clearAnnouncementText(state) {
            setTimeout(() => {
                state.announcementText = '';
            }, 500);
        }
    },
    actions: {
        setDistrictSlug({ commit }, districtSlug) {
            commit('setDistrictSlug', districtSlug);
        },
        getStyleDetails({ commit, state }) {
            return endpoints.getStyleDetails({ districtSlug: state.districtSlug })
                .then((response) => {
                    let style = document.createElement('style');
                    style.type = 'text/css';
                    style.innerHTML = '.app-primary-color { color: ' + response.PrimaryColor + ' !important; }';
                    document.getElementsByTagName('head')[0].appendChild(style);

                    if (!response.IsPublicMenuPublished) {
                        response.HeaderText = response.PreviewModeText;
                    }
                    commit('setPublicMenu', response);
                });
        },
        getSites({ commit, state }) {
            return endpoints.getSites({ districtSlug: state.districtSlug })
                .then((response) => {
                    commit('setSites', response);
                });
        },
        getMealTimes({ commit, state }) {
            return endpoints.getMealTimes({ SiteID: state.siteID })
                .then((response) => {
                    commit('setMealTimes', response);
                    commit('setMealTimeId', response[0].MealTimeId);
                });
        },
        getMenuItemCategories({ commit, state }) {
            return endpoints.getMenuItemCategories({ SiteID: state.siteID })
                .then((response) => {
                    response.forEach((c) => {
                        c.IconUrl += '?v=' + new Date().toISOString();
                    });
                commit('setCategories', response);
                commit('setCategoryId', response[0].CategoryId);
            });
        },
        getMenuItemAllergens({ commit, state }) {
            return endpoints
                .getMenuItemAllergens({
                    SiteID: state.siteID,
                    MealTimeId: state.mealTimeId,
                    CategoryId: state.categoryId,
                })
                .then((response) => {
                    commit('setNutritionItems', response);
                });
        },
        getMenuItemNutrition({ commit, state }) {
            return endpoints
                .getMenuItemNutrition({
                    SiteID: state.siteID,
                    MealTimeId: state.mealTimeId,
                    CategoryId: state.categoryId,
                })
                .then((response) => {
                    commit('setNutritionItems', response);
                });
        },
        getNutrition({ commit, state }) {
            return endpoints.getNutrition({ districtSlug: state.districtSlug })
                .then((response) => {
                    response.unshift({
                        DisplayInCalendarView: true,
                        DisplayInListView: true,
                        NutritionId: -1,
                        NutritionName: 'Serving Size',
                    });

                commit('setNutritionColumns', response);
            });
        },
        getAllergens({ commit, state }) {
            return endpoints.getAllergens({ districtSlug: state.districtSlug })
                .then((response) => {
                    response.push({
                        DisplayInCalendarView: true,
                        DisplayInListView: true,
                        AllergenId: -1,
                        AllergenName: 'More Details Url',
                        IsHidden: true
                    });
                
                commit('setAllergenColumns', response);
            });
        },
    },
};

export default publicMenuModule;
