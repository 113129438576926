<template src="./calendarPage.html"></template>

<script>
import { Day, Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-vue-schedule';
import adaMixin from '../../js/adaMixin.js';

export default {
    name: 'calendar-page',
    provide: {
        schedule: [Day, Week, WorkWeek, Month, Agenda]
    },
    mixins: [ adaMixin ],
    data() {
        return {
            paramsDto: {
                siteID: this.$store.state.PublicMenuModule.siteID,
                mealTimeId: this.$store.state.PublicMenuModule.mealTimeId,
                categoryId: this.$store.state.PublicMenuModule.categoryId,
            },
            dayMap: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
            ],
            days: [],
            tooltipData: {},
            weeks: [],
            selectedWeek: {},
            selectedWeekIndex: null,
            activeElement: null,
        };
    },
    computed: {
        publicMenu() {
            return this.$store.state.PublicMenuModule.publicMenu;
        },
        sites() {
            return this.$store.state.PublicMenuModule.sites;
        },
        mealTimes() {
            return this.$store.state.PublicMenuModule.mealTimes;
        },
    },
    watch: {
        selectedWeekIndex: function(newVal) {
            this.searchMenuItems();
        },
        'paramsDto.siteID': function(newVal) {
            this.$store.commit('PublicMenuModule/setSiteID', newVal);
            if(newVal) {
                this.$store.dispatch('PublicMenuModule/getMealTimes')
                    .then(response => {
                        this.paramsDto.mealTimeId = null;
                        setTimeout(() => {
                            let lunchMealPeriod = this.mealTimes.filter(m => m.DisplayName === 'Lunch')[0];
                            if(lunchMealPeriod) {
                                this.paramsDto.mealTimeId = lunchMealPeriod.MealTimeId;
                            } else {
                                this.paramsDto.mealTimeId = this.mealTimes[0].MealTimeId;
                            }
                        });
                    });
            }
        },
        'paramsDto.mealTimeId': function(newVal) {
            this.$store.commit('PublicMenuModule/setMealTimeId', newVal);
            if(newVal) {
                this.getCalendarWeeks();
            }
        }
    },
    methods: {
        searchMenuItems() {
            this.paramsDto.StartDate = this.weeks[this.selectedWeekIndex].StartDate;
            endpoints.getMenuItemsForCalendarWeek(this.paramsDto)
                .then(response => {
                    this.days = response;
                });
        },
        openTooltip(menuItemID, dailyMenuItemId, productionRecordId, $event) {
            let showTooltip = () => {
                let tooltipContainer = document.getElementsByClassName('app-tooltip-container')[0];
                tooltipContainer.style.display = 'block';
                
                if(window.innerWidth <= 576) {
                    let top = window.pageYOffset || document.documentElement.scrollTop;
                    tooltipContainer.style.top = top + 20 + 'px';
                }

                $event.stopPropagation();

                this.activeElement = document.activeElement;
                this.$refs.tooltipModal.focus();
                this.trapModalFocus(this.$refs.tooltipModal);
            }

            endpoints.getMenuItemDetailsForCalendar({
                MenuItemID: menuItemID,
                DailyMenuItemId: dailyMenuItemId || null,
                ProductionRecordId: productionRecordId || null
            })
                .then(response => {
                    this.tooltipData = response;
                    showTooltip();
                });
        },
        closeTooltip() {
            let tooltipContainer = document.getElementsByClassName('app-tooltip-container')[0];
            tooltipContainer.style.display = 'none';
            if(this.activeElement) {
                this.activeElement.focus();
            }
        },
        goToPreviousWeek() {
            this.selectedWeekIndex--;
        },
        goToNextWeek() {
            this.selectedWeekIndex++;
        },
        getCalendarWeeks() {
            endpoints.getCalendarWeeks({
                SiteID: this.paramsDto.siteID
            })
                .then(response => {
                    response.forEach((w, $index) => {
                        w.index = $index;
                    });

                    this.weeks = response;

                    if(this.selectedWeekIndex && this.weeks.filter(w => w.StartDate === this.weeks[this.selectedWeekIndex].StartDate)[0]) {
                        this.searchMenuItems();
                    } else {
                        this.selectedWeek = this.weeks.filter(w => w.IsCurrentWeek)[0];
                        let currentSelectedWeekIndex = this.selectedWeekIndex;
                        this.selectedWeekIndex = this.selectedWeek.index;
                        if(currentSelectedWeekIndex === this.selectedWeekIndex) {
                            this.searchMenuItems();
                        }
                    }
                });
        },
        btnNutritionOnClick() {
            this.$router.push('/' + this.districtSlug + '/nutrition');
            setTimeout(() => {
                let calendarPageElement = document.getElementById('menuNutritionPageNav');
                this.setFocus(calendarPageElement);
            }, 500);
        },
        btnAllergensOnClick() {
            this.$router.push('/' + this.districtSlug + '/allergen');
            setTimeout(() => {
                let calendarPageElement = document.getElementById('menuNutritionPageNav');
                this.setFocus(calendarPageElement);
            }, 500);  
        },
        btnCalendarOnClick() {
            this.$router.push('/' + this.districtSlug + '/calendar');
            setTimeout(() => {
                let calendarPageElement = document.getElementById('menuCalendarPageNav');
                this.setFocus(calendarPageElement);
            }, 500);
        },
        printPage() {
            window.print();
        },
    },
    created() {
        this.districtSlug = this.$route.params.districtSlug;

        if(!this.$store.state.PublicMenuModule.siteID) {
            let siteIDWatcher = this.$watch('$store.state.PublicMenuModule.siteID', (newVal) => {
                this.paramsDto.siteID = newVal;
                siteIDWatcher();
            });
        } else {
            this.getCalendarWeeks();
        }
    },
    mounted() {
        let targets = document.getElementsByClassName('app-tooltip-container');

        document.addEventListener('click', (event) => {
            let withinBoundaries = false;
            for(let i = 0; i < targets.length; i++) {
                withinBoundaries = event.composedPath().includes(targets[i]);
                if(withinBoundaries) {
                    break;
                }
            }

            for(let i = 0; i < targets.length; i++) {
                if (withinBoundaries) {
                    targets[i].style.display = 'block';
                } else {
                    targets[i].style.display = 'none';
                }
            }
        });
    },
};
</script>
