export default {
    data() {
        return {
            searchTimer: null
        };
    },
    methods: {
        trapModalFocus(modalEl) {
            let firstEl = modalEl;
            let focusableEls = tooltipModal.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            let lastEl = focusableEls[focusableEls.length - 1];

            modalEl.addEventListener('keydown', (e) => {
                if (e.key === 'Tab') {
                    if (e.shiftKey) {
                        if (document.activeElement === firstEl) {
                            lastEl.focus();
                            e.preventDefault();
                        } else if (document.activeElement === lastEl ) {
                            firstEl.focus();
                            e.preventDefault();
                        }
                    } else {
                        if (document.activeElement === lastEl) {
                            firstEl.focus();
                            e.preventDefault();
                        }
                    }
                }
            });
        }, 
        setFocus(parentElement) {
            if (parentElement) {
                let focusableEls = parentElement.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), .app-table-row');
                
                if (focusableEls.length !== 0) {
                    focusableEls[0].focus();
                }
            }
        },
        filteringDropdown() {
            let clearIcon = document.querySelector('.e-input-group-icon.e-clear-icon.e-icons');
            if (clearIcon) {
                clearIcon.setAttribute('role', 'button');
                clearIcon.setAttribute('name', 'Clear filter text')
                clearIcon.setAttribute('aria-label', 'Clear filter text');

                setTimeout(() => {
                    let count = document.querySelectorAll('.e-list-item').length;
                    
                    if (count === 0) {
                        this.setAndClearAnnouncement('No records found.')
                    } else {
                        this.setAndClearAnnouncement('Filtered to ' + count + ' results');
                    }
                }, 500)
            }
        },
        filteringTable(items) {
            let count = items.length;

            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
            }

            this.searchTimer = setTimeout(() => {
                if (count === 0) {
                    this.setAndClearAnnouncement('No records found.');
                } else {
                    this.setAndClearAnnouncement('Filtered to ' + count + ' results');
                }
            }, 1000);     
        },
        setDropdownAriaLabel(id, label) {
            let selects = document.getElementsByTagName('select');

            for(let i = 0; i < selects.length; i++) {
                selects[i].parentElement.removeAttribute('aria-labelledby');
                selects[i].parentElement.removeAttribute('aria-describedby');

                selects[i].setAttribute('aria-label', selects[i].nextSibling.ariaLabel);

                if (selects[i].id === id) {
                    selects[i].parentElement.setAttribute('aria-label', label);
                }
            }
        },
        announceDropdownSelection(args) {
            this.setAndClearAnnouncement(args.itemData.Name);
        },
        setAndClearAnnouncement(text) {
            this.$store.commit('PublicMenuModule/setAnnouncementText', text);
            this.$store.commit('PublicMenuModule/clearAnnouncementText');
        },
        traverseNavList(e, className) {
            let navButtons = Array.from(document.querySelectorAll('.' + className));
            let currentIndex = navButtons.indexOf(document.activeElement);

            if (e.key === 'ArrowLeft') {
                if (currentIndex === 0) {
                    return;
                }

                navButtons[currentIndex - 1].focus();
            }

            if (e.key === 'ArrowRight') {
                if (currentIndex === navButtons.length - 1) {
                    return;
                }

                navButtons[currentIndex + 1].focus();
            }
        },
    },
};

