<template src="./adaTreegrid.html"></template>

<script>
import adaMixin from '../../js/adaMixin';

export default {
    name: 'ada-treegrid',
    mixins: [ adaMixin ],
    props: [
        'tableID',
        'caption',
        'columns',
        'hasBlankTopLeftHeader',
        'hasBlankTopRightHeader',
        'columnWidth',
        'items',
        'childMapping',
        'nutritionCategory',
        'showSymbol',
        'columnKey',
    ],
    data() {
        return {};
    },
    computed: {
        totalColumns() {
            let total = this.columns.length;
            
            if (this.hasBlankTopLeftHeader) {
                total++;
            }

            if (this.hasBlankTopRightHeader) {
                total++;
            }

            return total;
        },
        allRows() {
            return Array.from(document.querySelectorAll('tr'));
        },
    },
    methods: {
        getValue(item, column) {
            let nutrition = item[this.nutritionCategory].find((x) => x.Key === column[this.columnKey]);

            if (nutrition) {
                return nutrition.Html || nutrition.Value;
            }
        },
        getLabel(item, column) {
            let nutrition = item[this.nutritionCategory].find((x) => x.Key === column[this.columnKey]);

            if (nutrition) {
                return nutrition.AriaLabel;
            }
        },
        collapseRow(row, btn) {
            let allRows = Array.from(document.querySelectorAll('tr'));
            btn.setAttribute('aria-expanded', 'false');
            
            let childRows = +row.getAttribute('aria-setsize');
            let currentRowIndex = +allRows.indexOf(row);

            for (let i = currentRowIndex + 1; i <= (currentRowIndex + childRows); i++) {
                allRows[i].classList.add('app-display-none');
            }
        },
        expandRow(row, btn) {
            let allRows = Array.from(document.querySelectorAll('tr'));
            btn.setAttribute('aria-expanded', 'true');
            
            let childRows = +row.getAttribute('aria-setsize');
            let currentRowIndex = +allRows.indexOf(row);

            for (let i = currentRowIndex + 1; i <= (currentRowIndex + childRows); i++) {
                allRows[i].classList.remove('app-display-none');
            }
        },
        toggleExpandCollapseRow(index) {
            let row = document.getElementById('tableRow' + index);
            let btn = document.getElementById('expandCollapseBtnRow' + index);

            if (row) {
                if (btn.getAttribute('aria-expanded') === 'true') {
                    this.collapseRow(row, btn);
                } else {
                    this.expandRow(row, btn);
                }
            }
        },
        getNonHiddenRows() {
            let allRows = Array.from(document.querySelectorAll('tr'));

            let nonHiddenRows = allRows.filter((row) => {
                return !row.classList.contains('app-display-none');
            });

            return nonHiddenRows;
        },
        onKeydown(e) {
            let allRows = Array.from(document.querySelectorAll('tr'));
            let activeElement = document.activeElement;
            let isExpanded = activeElement.getAttribute('aria-expanded');
            let parentRow = activeElement.parentElement;
            let cellsInCurrentRow = Array.from(parentRow.querySelectorAll('td'));
            let cellIndex = cellsInCurrentRow.indexOf(activeElement);

            if (e.ctrlKey) {
                switch (e.key) {
                    case 'Home':                    
                        //If a row has focus, moves focus to the first row.
                        if (activeElement.nodeName === 'TR') {
                            allRows[1].focus();
                        }

                        //If a cell has focus, moves focus to the cell in the first row in the same column as the cell that had focus.
                        if (activeElement.nodeName === 'TD') {
                            let cellsInFirstRow = Array.from(allRows[1].querySelectorAll('td'));
                            cellsInFirstRow[cellIndex].focus();
                        }

                        break;
                    case 'End':
                        //If a row has focus, moves focus to the last row.
                        if (activeElement.nodeName === 'TR') {
                            allRows[allRows.length - 1].focus();
                        }


                        //If a cell has focus, moves focus to the cell in the last row in the same column as the cell that had focus. 
                        if (activeElement.nodeName === 'TD') {
                            let cellsInLastRow = Array.from(allRows[allRows.length - 1].querySelectorAll('td'));
                            cellsInLastRow[cellIndex].focus();
                        }
                        
                        break;
                }
            } else {
                switch (e.key) {
                    case 'ArrowLeft':                       
                        //If a row is focused, and it is expanded, collapses the current row.
                        if (activeElement.nodeName === 'TR') {
                            if (isExpanded === 'true') {
                                this.collapseRow(activeElement);
                            } 
                        }

                        //If a cell in the first column is focused, focuses the row.
                        //If a cell in a different column is focused, moves focus one cell to the left.
                        if (activeElement.nodeName === 'TD') {
                            if (cellIndex === 0) {
                                parentRow.focus();
                            } else {
                                cellsInCurrentRow[cellIndex - 1].focus();
                            }
                        }

                        break;
                    case 'ArrowRight':
                        //If a row is focused, and it is collapsed, expands the current row.
                        //If a row is focused, and it is expanded, focuses the first cell in the row.
                         if (activeElement.nodeName === 'TR') {
                            if (isExpanded === 'false') {
                                this.expandRow(activeElement);
                            } else {
                                let firstCell = activeElement.querySelector('td');
                                firstCell.focus();
                            }
                        }

                        //If a cell is focused, moves one cell to the right.
                        //If focus is on the right most cell, focus does not move.
                        if (activeElement.nodeName === 'TD') {
                            if (cellIndex < (cellsInCurrentRow.length - 1)) {
                                cellsInCurrentRow[cellIndex + 1].focus();
                            }
                        }

                        break;
                    case 'ArrowUp':
                        //If row is focused, moves focus one row up
                        //If focus is on the top row, focus does not move.

                        if (activeElement.nodeName === 'TR') {
                            let nonHiddenRows = this.getNonHiddenRows();
                            let index = nonHiddenRows.indexOf(activeElement);

                            if (index !== 0) {
                                nonHiddenRows[index - 1].focus();
                            }
                        }

                        // If cell is focused, moves focus one cell up
                        // If focus is on a cell in the top row, focus does not move.
                        if (activeElement.nodeName === 'TD') {
                            let nonHiddenRows = this.getNonHiddenRows();
                            let rowIndex = nonHiddenRows.indexOf(parentRow);

                            if (rowIndex !== 0) {
                                let cellsInRowAbove = nonHiddenRows[rowIndex - 1].querySelectorAll('td');
                                cellsInRowAbove[cellIndex].focus();
                            }
                        }

                        break;
                    case 'ArrowDown':
                        //If row is focused, moves focus one row down
                        //If focus is on the bottom row, focus does not move.
                        if (activeElement.nodeName === 'TR') {
                            let nonHiddenRows = this.getNonHiddenRows();
                            let rowIndex = nonHiddenRows.indexOf(activeElement);

                            if (rowIndex !== this.items.length) {
                                nonHiddenRows[rowIndex + 1].focus();
                            }
                        }

                        //If cell is focused, moves focus cell row down
                        //If focus is a cell in the bottom row, focus does not move.
                        if (activeElement.nodeName === 'TD') {
                            let nonHiddenRows = this.getNonHiddenRows();
                            let rowIndex = nonHiddenRows.indexOf(parentRow);

                            if (rowIndex !== this.items.length) {
                                let cellsInRowAbove = nonHiddenRows[rowIndex + 1].querySelectorAll('td');
                                cellsInRowAbove[cellIndex].focus();
                            }
                        }

                        break;
                    case 'Home':
                        //If a row is focused, moves to the first row.
                        if (activeElement.nodeName === 'TR') {
                            allRows[1].focus();
                        }

                        //If a cell is focused, moves focus to the first cell in the row containing focus.
                        if (activeElement.nodeName === 'TD') {
                            cellsInCurrentRow[0].focus();
                        }

                        break;
                    case 'End':
                        //If a row is focused, moves to the last row.
                        if (activeElement.nodeName === 'TR') {
                            allRows[allRows.length - 1].focus();
                        }

                        //If a cell is focused, moves focus to the last cell in the row containing focus.
                        if (activeElement.nodeName === 'TD') {
                            cellsInCurrentRow[cellsInCurrentRow.length - 1].focus();
                        }

                        break;
                }
            }

        }
    },
    mounted() {
        let treegrid = document.querySelector('.app-treegrid');

        if (treegrid) {
            treegrid.addEventListener('keydown', (e) => {
                this.onKeydown(e);
            });
        }
    },
    created() {
        if (this.items.length === 0) {
            this.setAndClearAnnouncement('No results found.');
        }
    },
};
</script>

<style scoped>
@import './adaTreegrid.css';
</style>
