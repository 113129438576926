<template src="./nutritionPage.html"></template>

<script>
import { Freeze } from '@syncfusion/ej2-vue-treegrid';
import adaMixin from '../../js/adaMixin.js';
import adaTreegrid from '../../components/adaTreegrid/adaTreegrid.vue';

export default {
    name: 'nutrition-page',
    provide: {
        treegrid: [Freeze],
    },
    mixins: [adaMixin],
    components: { 'ada-treegrid': adaTreegrid },
    data() {
        return {
            paramsDto: {
                siteID: this.$store.state.PublicMenuModule.siteID,
                mealTimeId: this.$store.state.PublicMenuModule.mealTimeId,
                categoryId: this.$store.state.PublicMenuModule.categoryId,
            },
            nutritionTableColumns: [],
            nutritionTableSortBy: {
                columns: [{ field: 'DisplayName', direction: 'Ascending' }],
            },
            nutritionTableActions: [],
            selectedRow: null,
            searchText: '',
            hideGrid: true,
        };
    },
    watch: {
        'paramsDto.siteID': function (newVal) {
            this.$store.commit('PublicMenuModule/setSiteID', newVal);
            if (newVal) {
                this.$store
                    .dispatch('PublicMenuModule/getMealTimes')
                    .then((response) => {
                        let mealTimeId = this.paramsDto.mealTimeId;
                        this.paramsDto.mealTimeId = null;
                        setTimeout(() => {
                            let hasMealTime = this.mealTimes.filter((m) => m.MealTimeId === mealTimeId)[0];
                            if (!hasMealTime) {
                                let lunchMealPeriod = this.mealTimes.filter((m) => m.DisplayName === 'Lunch')[0];
                                if (lunchMealPeriod) {
                                    this.paramsDto.mealTimeId = lunchMealPeriod.MealTimeId;
                                } else {
                                    this.paramsDto.mealTimeId = this.mealTimes[0].MealTimeId;
                                }
                            } else {
                                this.paramsDto.mealTimeId = mealTimeId;
                            }
                        });
                        return this.$store.dispatch('PublicMenuModule/getMenuItemCategories');
                    })
                    .then(() => {
                        this.paramsDto.categoryId = this.categories[0].CategoryId;
                        this.searchNutrition();
                    });
            }
        },
        'paramsDto.mealTimeId': function (newVal) {
            this.$store.commit('PublicMenuModule/setMealTimeId', newVal);
            if (this.paramsDto.mealTimeId && this.paramsDto.categoryId) {
                this.searchNutrition();
            }
        },
        $route: function (newVal) {
            let categories = [];

            if (this.$route.name.includes('allergen')) {
                categories = this.$store.state.PublicMenuModule.categories.filter((c) => c.DisplayInAllergensListView);
            } else {
                categories = this.$store.state.PublicMenuModule.categories.filter((c) => c.DisplayInNutritionListView);
            }

            if (categories.length === 0) {
                return;
            }

            let pageIncludesSelectedCategory = categories.filter((c) => c.CategoryId === this.paramsDto.categoryId)[0];
            if (!pageIncludesSelectedCategory) {
                this.paramsDto.categoryId = categories[0].CategoryId;
            }

            this.searchNutrition();
        },
    },
    computed: {
        route() {
            return this.$route.name;
        },
        publicMenu() {
            return this.$store.state.PublicMenuModule.publicMenu;
        },
        sites() {
            return this.$store.state.PublicMenuModule.sites;
        },
        mealTimes() {
            return this.$store.state.PublicMenuModule.mealTimes;
        },
        categories() {
            if (this.$route.name.includes('allergen')) {
                return this.$store.state.PublicMenuModule.categories.filter((c) => c.DisplayInAllergensListView);
            } else {
                return this.$store.state.PublicMenuModule.categories.filter((c) => c.DisplayInNutritionListView);
            }
        },
        nutritionTableItems() {
            let items = DMUtils.copy(this.$store.state.PublicMenuModule.nutritionItems);
            let flattenedItems = [];

            if (this.searchText) {
                items = this.filterNutritionItems(items);
                this.filteringTable(items);
            }

            items.forEach((item) => {
                flattenedItems.push(this.flattenTableItem(item, 1));

                item.ChildItems.forEach((ci) => {
                    flattenedItems.push(this.flattenTableItem(ci, 2));
                });
            });

            return flattenedItems;
        },
    },
    methods: {
        nutritionTableRowOnClick(args) {
            this.selectedRow = args;
        },
        flattenTableItem(item, level) {
            if (this.route === 'nutrition') {
                this.mapNutrition(item.NutritionAmounts);

                let serving = {
                    Key: 'Serving Size',
                    Value: item.ServingDisplay,
                };

                item.NutritionAmounts.unshift(serving);
            }

            if (this.route === 'allergen') {
                
                let moreDetails = {
                    Key: 'More Details Url',
                    Value: item.MoreDetailsUrl,
                };

                item.Allergens.push(moreDetails);
                this.mapAllergens(item.Allergens);
            }

            if (item.ChildItems && item.ChildItems.length > 0) {
                item.IsParentItem = true;
            }

            item.Level = level;

            return item;
        },
        filterNutritionItems(items) {
            items.forEach((item) => {
                    item.ChildItems = item.ChildItems.filter((childItem) => {
                        return (
                            childItem.DisplayName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) ||
                            item.DisplayName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase())
                        );
                    });
                });

            return items.filter((item) => {
                return (
                    item.DisplayName.toLocaleLowerCase().includes(this.searchText.toLocaleLowerCase()) ||
                    item.ChildItems.length > 0
                );
            });
        },
        searchNutrition() {
            let endpoint = 'getMenuItemNutrition';
            if (this.$route.name.includes('allergen')) {
                endpoint = 'getMenuItemAllergens';
            }

            this.hideGrid = true;
            return this.$store.dispatch('PublicMenuModule/' + endpoint)
                .then(() => {
                    this.hideGrid = false;
                });
        },
        mapNutrition(nutritionAmounts) {
            nutritionAmounts.forEach((ni) => {
                ni.Key = ni.NutritionName;

                if (ni.AmountF === '*') {
                    ni.Html = `
                        <span aria-hidden="true">${ni.AmountF}</span>
                        <span class="app-hide-visually">Not set by district</span>`;
                } else if (!ni.AmountF) {
                    ni.Html = '<span class="app-hide-visually">Not applicable</span>';
                } else {
                    ni.Html = `<span>${ni.AmountF}</span>`;
                }
            })
        },
        mapAllergens(allergens) {
            allergens.forEach((al) => {
                if (al.Key === 'More Details Url' && al.Value) {
                    al.Html = `<a href="${al.Value}" target="_blank">More Details</a>`
                }

                if (al.Value === true) {
                    al.Html = `
                        <i class="fa fa-check" aria-hidden="true"></i>
                        <span class="app-hide-visually">Allergen is present</span>`;
                } else if (al.Value === null) {
                    al.Html = `
                        <i class="fa fa-asterisk" aria-hidden="true"></i>
                        <span class="app-hide-visually">Not set by district</span>`;
                } else if (al.Value === false) {
                    al.Html = '<span class="app-hide-visually">Not applicable</span>';
                }
            });
        },
        setSelectedButton(id) {
            let btns = document.querySelectorAll('.app-navigation-button');

            btns.forEach((btn) => {
                if (btn.id === id) {
                    btn.ariaSelected = 'true';
                    btn.tabIndex = 0;
                } else {
                    btn.ariaSelected = 'false';
                    btn.tabIndex = -1;
                }
            });
        },
        nutritionCategoryOnClick(categoryId) {
            this.paramsDto.categoryId = categoryId;

            this.$store.commit('PublicMenuModule/setCategoryId', categoryId);
            if (this.paramsDto.mealTimeId && this.paramsDto.categoryId) {
                this.searchNutrition()
                .then(() => {
                    let parentElement = document.querySelector('tbody');
                    this.setFocus(parentElement);
                });
            }
        },
        btnNutritionOnClick() {
            this.$router.push('/' + this.districtSlug + '/nutrition');
            setTimeout(() => {
                let nutritionPageElement = document.getElementById('menuNutritionPageNav');
                this.setFocus(nutritionPageElement);
            }, 500);
        },
        btnAllergensOnClick() {
            this.$router.push('/' + this.districtSlug + '/allergen');
            setTimeout(() => {
                let nutritionPageElement = document.getElementById('menuNutritionPageNav');
                this.setFocus(nutritionPageElement);
            }, 500);  
        },
        btnCalendarOnClick() {
            this.$router.push('/' + this.districtSlug + '/calendar')
            setTimeout(() => {
                let calendarPageElement = document.getElementById('menuCalendarPageNav');
                this.setFocus(calendarPageElement);
            }, 500)
        },
        printPage() {
            window.print();
        },
    },
    created() {
        this.districtSlug = this.$route.params.districtSlug;

        if (!this.$store.state.PublicMenuModule.siteID) {
            let siteIDWatcher = this.$watch('$store.state.PublicMenuModule.siteID', (newVal) => {
                this.paramsDto.siteID = newVal;
                siteIDWatcher();
            });
        } else {
            this.$store.dispatch('PublicMenuModule/getMenuItemCategories')
                .then(() => {
                    if (this.paramsDto.categoryId !== this.categories[0].CategoryId) {
                        this.paramsDto.categoryId = this.categories[0].CategoryId;
                    } else {
                        this.searchNutrition();
                    }
                });
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.$route.name === 'nutrition') {
                this.setSelectedButton('btnNutrition');
            }

            if (this.$route.name === 'allergen') {
                this.setSelectedButton('btnAllergen');
            }
        }, 1000);
    },
};
</script>
